import React from 'react';
import { Row, Col } from 'react-bootstrap';
import Header from './Header';
import HomeImageSlider from './HomeImageSlider';
import HomePageSearchBar from '../../components/SearchBar/HomeSearchBar';
import LoginSignUpVector from '../../components/Vector/LoginSignupVector';
import HomePageFilterBttn from '../../components/filterButtons/HomePageFilterBttn';
import VegNonvegFilter from '../../components/filterButtons/VegNonvegFilter';
import RestaurantCard from '../ReusableComponent/restaurantCard';
import ExperienceBanner from './ExperienceBanner';
import ReferEarnBanner from '../ReusableComponent/ReferAndEarn';
import RestaurantListMobile from '../ReusableComponent/RestaurantListMobile';
import Sidebar from './HomeSideBar';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import RestaurantListWeb from '../ReusableComponent/RestaurantListWeb';

function HomePage() {
  const windowWidth = useWindowWidth();

  return (
    <div>
      <Row>
        {windowWidth > 1024 && (
          <Col lg={3} md={3} className="sidebar">
            <Sidebar />
          </Col>
        )}

        <Col lg={windowWidth > 1024 ? 9 : 12}>
          <Header />

          <div className="main-content">
            <HomeImageSlider />

            <div
              className={`${
                windowWidth > 1024 ? 'd-flex align-items-center' : 'd-flex flex-column'
              }`}
              style={{
                gap: '10px',
                justifyContent: windowWidth > 1024 ? 'space-between' : 'center',
                marginTop: '10px',
              }}
            >
              <HomePageSearchBar />
              <HomePageFilterBttn />
            </div>

            <VegNonvegFilter />

            <div
              className={`d-flex ${windowWidth > 1024 ? 'flex-row' : 'flex-column'} 
                ${windowWidth <= 1024 ? 'justify-content-center align-items-center' : ''}`}
              style={{
                gap: '20px',
                marginTop: '15px',
              }}
            >
              <div style={{ maxWidth: windowWidth <= 1024 ? '90%' : '100%' }}>
                <RestaurantCard />
              </div>

            </div>

            <ExperienceBanner />

            {windowWidth > 1024 ? <RestaurantListWeb /> : <RestaurantListMobile />}

            <ReferEarnBanner />
          </div>
        </Col>
      </Row>

      <style jsx>{`
        .main-content {
          padding: 5px;
        }
      `}</style>
    </div>
  );
}

export default HomePage;
