import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SaveButton from './../healthPreferences/SaveButton';
import image from '../../assets/images/IconsandImages/image.png';
import pencilGrey from '../../assets/images/IconsandImages/pencilGrey.png'; 

const Allergies = () => {
  const allergies = [
    'Mustard', 'Peanut', 'Milk', 'Fish', 'Nuts', 'Almond',
    'Egg', 'Sesame', 'Gluten', 'Soy', 'Others', 'No Allergies',
    'Shellfish', 'Pollen', 'Celery', 'Mushroom', 'Lactose', 'Mushrooms',
    'Crustacean', 'Molluscs', 'Sulphites', 'Dry fruits', 'Lupin',
    'Home Chefs/Home Bakers', 'Food Colors', 'No Allergies'
  ];

  const [selectedAllergies, setSelectedAllergies] = useState([]);
  const [manualAllergy, setManualAllergy] = useState('');
  const navigate = useNavigate();

  const handleAllergyClick = (allergy) => {
    setSelectedAllergies((prevAllergies) =>
      prevAllergies.includes(allergy)
        ? prevAllergies.filter((item) => item !== allergy)
        : [...prevAllergies, allergy]
    );
  };

  const handleAddManualAllergy = () => {
    if (manualAllergy && !selectedAllergies.includes(manualAllergy)) {
      setSelectedAllergies([...selectedAllergies, manualAllergy]);
      setManualAllergy(''); // Clear input field after adding
    }
  };

  return (
    <div className="Allergies-page">
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="preferences">
            <h1>Allergies</h1>
            <p>Your safety is important to us. Please tell us about any allergies you have, and we'll ensure your recommendations are free from these allergens.
            We'll also alert you if any item you're allergic to is added to the menu.</p>
            
            {/* Manual Add Allergy Section with Pencil Icon */}
            <div className="manual-add-container">
              <input
                type="text"
                placeholder="Add your allergies manually"
                value={manualAllergy}
                onChange={(e) => setManualAllergy(e.target.value)}
                className="manual-add-input"
              />
              <button onClick={handleAddManualAllergy} className="manual-add-button">
                <img src={pencilGrey} alt="Add" className="pencil-icon" />
              </button>
            </div>

            <div className="options-container">
              {allergies.map((allergy) => (
                <div
                  key={allergy}
                  className={`option ${selectedAllergies.includes(allergy) ? 'selected' : ''}`}
                  onClick={() => handleAllergyClick(allergy)}
                >
                  {allergy} <span className="plus">+</span>
                </div>
              ))}
            </div>

            <SaveButton
              onClick={() => navigate('/dietaryRestrictions')}  
              active={selectedAllergies.length > 0}
            />
          </div>
        </Col>

        <Col lg={6} md={6} className="image-section">
          <img src={image} alt="Favorite Cuisines" className="profile-image" />
        </Col>
      </Row>
    </div>
  );
};

export default Allergies;
