import React, { useState, useEffect } from 'react';
import { Col, Row, Image } from 'react-bootstrap';

const restaurants = [
  {
    name: 'Huber & Holly',
    cuisines: 'Italian, Chinese, +2',
    category: 'Dessert Parlour',
    distance: '3.2mi',
    closingTime: '11:00PM',
    rating: '4.2',
  },
  {
    name: 'Moon Shine Bar Restaurant',
    cuisines: 'Bar, Continental, +3',
    category: 'Dessert Parlour',
    distance: '2.8mi',
    closingTime: '12:00AM',
    rating: '4.5',
  },
  {
    name: 'Coolato Gelato',
    cuisines: 'Ice Cream, Desserts',
    category: 'Dessert Parlour',
    distance: '1.5mi',
    closingTime: '10:00PM',
    rating: '4.3',
  },
];

const RestaurantCard = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <Row className="d-flex flex-row justify-content-start flex-wrap">
      {restaurants.map((restaurant, index) => (
        <Col key={index} md={4} className="d-flex justify-content-center">
          <div
            className="restaurant_title_card"
            style={{
              position: 'relative',
              borderRadius: '25px',
              border: '1px solid lightgray',
              backgroundColor: 'white',
              marginTop: '20px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              width: '100%',
              maxWidth: '400px',
              margin: '0 0px',
              marginBottom:'20px'
            }}
          >
            <div className="justify-content-between align-items-center d-flex first_layer">
              <span
                className="py-1 px-4 f2-500"
                style={{
                  backgroundColor: '#F86B00',
                  color: 'white',
                  borderRadius: '15px',
                  fontSize: '12px',
                  marginTop: '-9px',
                }}
              >
                PREMIUM
              </span>
            </div>

            <Row className="p-1 pb-3 m-0">
              <Col xs={4} className="d-flex align-items-center">
                <Image
                  src={require('../../assets/images/IconsandImages/HH.png')}
                  alt="restaurantImage"
                  className="restaurant_card_image"
                  style={{ borderRadius: '50%', width: '90px', marginTop: '0px' }}
                />
                <div className="pl-4">
                  <div
                    className="f12-500"
                    style={{
                      fontWeight: 'bold',
                      fontSize: '50px',  
                      whiteSpace: 'nowrap',
                      marginTop: '-30px',
                    }}
                  >
                    {restaurant.name}
                  </div>
                  <div
                    className="f12-400 restaurant_cusine_color"
                    style={{
                      fontSize: '40px',
                      marginTop: '2px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    {restaurant.cuisines}
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="pb-1 mt-0.9 justify-content-center">
            <Col xs={4} className="d-flex justify-content-center">
              <div className="f14-500 d-flex align-items-center">
                <Image
                  src={require('../../assets/images/landingPageAssets/dessert.png')}
                  alt="Image"
                  className="star_rating"
                  style={{ width: '18px', height: '18px', marginTop: '-30px', marginLeft: '-50px' }}
                />
                <div
                  className="f10-600 star_color align-items-center pl-1"
                  style={{ marginTop: '-30px', marginLeft: '10px', fontSize: '13px' }}
                >
                  {restaurant.category}
                </div>
              </div>
            </Col>
            <Col xs={4} className="d-flex justify-content-center">
              <div className="f14-500 star_color d-flex align-items-center">
                <Image
                  src={require('../../assets/images/IconsandImages/Group 87878.png')}
                  alt="vegIndicator"
                  className="veg_indicator"
                  style={{ width: '18px', height: '18px', marginTop: '-30px', marginLeft: '-10px' }}
                />
                <div
                  className="f14-600 star_color align-items-center pl-1"
                  style={{
                    marginTop: '-30px',
                    fontWeight: 'normal',
                    marginLeft: '10px',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Pure Vegetarians
                </div>
              </div>
            </Col>
          </Row>

          <Row className="pb-1 mt-2 justify-content-center">
            <Col xs={4} className="d-flex justify-content-center">
              <div className="f14-500 d-flex align-items-center">
                <Image
                  src={require('../../assets/images/IconsandImages/rupee.png')}
                  alt="Cost for two"
                  className="rupee_png"
                  style={{ width: '16px', marginTop: '-12px', marginLeft: '-70px' }}
                />
                <div className="f14-500 star_color align-items-center pl-1" style={{ marginTop: '-12px', fontWeight: 'normal',marginLeft:'10px' }}>
                  Cost for two
                </div>
              </div>
            </Col>
            <Col xs={4} className="d-flex justify-content-center">
              <div className="f14-500 star_color d-flex align-items-center">
                <Image
                  src={require('../../assets/images/IconsandImages/charging.png')}
                  alt="Image"
                  className="charging_icon"
                  style={{ width: '16px', marginTop: '-12px', marginLeft: '-18px' }}
                />
                <div className="f14-600 star_color align-items-center pl-1" style={{ marginTop: '-12px', marginLeft: '10px', whiteSpace: 'nowrap' }}>
                  Charging Point
                </div>
              </div>
            </Col>
          </Row>

          <Image
            src={require('../../assets/images/IconsandImages/Line 2256.png')}
            alt="divider"
            style={{ width: '100%', marginTop: '1px', marginBottom: '5px' }}
          />

          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <Image
                src={require('../../assets/images/IconsandImages/direction.png')}
                alt="direction"
                className="direction_icon"
                style={{ width: '25px', height: '20px', paddingLeft: '8px',marginBottom:'10px' }}
              />
              <span style={{ paddingLeft: '15px',marginBottom:'10px' }}>{restaurant.distance}</span>
            </div>
            <div className="d-flex align-items-center">
              <Image
                src={require('../../assets/images/IconsandImages/clock1.png')}
                alt="clockIcon"
                className="clock_icon"
                style={{ width: '16px',marginBottom:'10px' }}
              />
              <span style={{ paddingLeft: '4px',marginBottom:'10px' }}>Closes at {restaurant.closingTime}</span>
            </div>
            <div className="d-flex align-items-center">
              <Image
                src={require('../../assets/images/IconsandImages/star.png')}
                alt="starRating"
                className="star_rating"
                style={{ width: '16px',marginBottom:'10px' }}
              />
              <span style={{ paddingRight: '25px',marginBottom:'10px' }}>{restaurant.rating}</span>
            </div>
          </div>

          <div
            className="live_icon_position"
            style={{
              position: 'absolute',
              top: '10px',
              right: '50px',
            }}
          >
            <Image
              src={require('../../assets/images/IconsandImages/live1.png')}
              alt="liveIcon"
              className="live_icon"
              style={{ width: '30px' }}
            />
          </div>
          <div
            className="verification"
            style={{
              position: 'absolute',
              top: '10px',
              right: '15px',
            }}
          >
            <Image
              src={require('../../assets/images/IconsandImages/surface1.png')}
              alt="verification"
              className="verifiction"
              style={{ width: '15px' }}
            />
          </div>          
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default RestaurantCard;
