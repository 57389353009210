import React, { useState } from 'react';
import amealiologowithtext from '../../assets/images/IconsandImages/amealiologowithtext.png';
import ProfilePicture from '../../assets/images/IconsandImages/ProfilePicture.png';
import addIcon from '../../assets/images/IconsandImages/addIcon.png';
import { Image } from 'react-bootstrap';
import { Typography, Modal, Button, Box } from '@mui/material';
import { assets } from '../../assets/assets';

const ProfilePictureComponent = () => {
  const [image, setImage] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
      setShowPopup(false);
    } else {
      alert('Please select an image less than 2MB.');
    }
  };

  const handleTakePhoto = () => {
    // Use the device camera (requires user permission and HTML5 APIs)
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.capture = 'camera';
    inputElement.onchange = (e) => handleImageUpload(e);
    inputElement.click();
  };

  const handleSelectFromLibrary = () => {
    const inputElement = document.createElement('input');
    inputElement.type = 'file';
    inputElement.accept = 'image/*';
    inputElement.onchange = (e) => handleImageUpload(e);
    inputElement.click();
  };

  const handleDeleteImage = () => {
    setImage(null);
    setShowPopup(false);
  };

  const togglePopup = () => setShowPopup(!showPopup);

  return (
    <>
      <div className="profile-container">
        <img src={amealiologowithtext} alt="Amealio Logo" className="logo" />

        <Typography className="f24-800" gutterBottom>
          Your First Impressions
        </Typography>

        <Typography className="f18-700" align="left" gutterBottom>
          Add a Profile Picture
        </Typography>

        <Typography
          className="f16-500"
          color={assets.colors.greySubText2}
          gutterBottom
        >
          Select an image less than 2MB. Max Dimensions: 1500x1500.
        </Typography>

        <Image
          src={image || ProfilePicture}
          alt="Profile"
          style={{ width: 80, borderRadius: '50%' }}
          className="mt-3"
        />
        <Image
          src={addIcon}
          alt="Add Icon"
          className="add-icon"
          onClick={togglePopup}
        />
      </div>

      <Modal open={showPopup} onClose={togglePopup}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 300,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography variant="h6" gutterBottom>
            Update Profile Picture
          </Typography>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={handleTakePhoto}
            className="mb-2"
          >
            Take Photo
          </Button>
          <Button
            variant="contained"
            color="error"
            fullWidth
            onClick={handleSelectFromLibrary}
            className="mb-2"
          >
            Choose from Library
          </Button>
          {image && (
            <Button
              variant="contained"
              color="error"
              fullWidth
              onClick={handleDeleteImage}
            >
              Delete Photo
            </Button>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ProfilePictureComponent;
