import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SaveButton from './SaveButton';
import image from '../../assets/images/IconsandImages/image.png';
import search from '../../assets/images/IconsandImages/search.png'; 

const Places = () => {
  const cuisines = [
    'Bakery', 'Barbeque', 'Boutique', 'Basserie', 'Buffet', 'Cafe',
    'Cafeteria', 'Bakery', 'Barbecue', 'Buffet', 'Candy shop', 'Casual Dinig',
    'Burger Place', 'Chat House', 'Cloud Kitchen', 'Coffee House', 'Commissary', 'Concept Cafe',
    'Confectionary', 'Dark Kitchen', 'Dessert Parlour', 'Drive-in', 'Drive-thru',
    'Ethnic', 'Event Mangement', 'Family Style Restaurant', 'Family Style', 'Fast Food', 'Fast Casual',
    'Food Court', 'Food Truck', 'Home Chef','Paan Shop','Pizzeria','Pubs/Bars','OSR','Ouick Bites','Sweet Shop',
    'Tea Stall'
  ];

  const [selectedCuisines, setSelectedCuisines] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  // Initialize navigate hook
  const navigate = useNavigate();

  const handleCuisineClick = (cuisine) => {
    setSelectedCuisines((prevCuisines) =>
      prevCuisines.includes(cuisine)
        ? prevCuisines.filter((item) => item !== cuisine)
        : prevCuisines.length < 10
        ? [...prevCuisines, cuisine]
        : prevCuisines
    );
  };

  const filteredCuisines = cuisines.filter((cuisine) =>
    cuisine.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="favorite-cuisines-page">
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="preferences">
            <h1>Preferred Places</h1>
            <p>Select maximum 10 (Mandatory)</p>
            
            {/* Search Icon */}
            <div className="search-container">
              <img src={search} alt="Search" className="search-icon" />
              <input
                type="text"
                placeholder="Search for cuisines"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </div>

            <div className="options-container">
              {filteredCuisines.map((cuisine) => (
                <div
                  key={cuisine}
                  className={`option ${selectedCuisines.includes(cuisine) ? 'selected' : ''}`}
                  onClick={() => handleCuisineClick(cuisine)}
                >
                  {cuisine} <span className="plus">+</span>
                </div>
              ))}
            </div>

            <SaveButton
              onClick={() => navigate('/profileSetupMenu')}  
              active={selectedCuisines.length === 10}
            />
          </div>
        </Col>

        <Col lg={6} md={6} className="image-section">
          <img src={image} alt="Favorite Cuisines" className="profile-image" />
        </Col>
      </Row>
    </div>
  );
};

export default Places;
