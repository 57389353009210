export const URL = {
  RESTAURANT_DETAILS: 'listRestaurant',
  LOGIN: 'authentication/login',
  FORGOT_PASSWORD: 'authentication/forgot-password',
  REFRESH: 'authentication/refresh',
  LOGOUT: 'authentication/logout',
  USER_SETTING: '/user/settings',
  RESTAURANT_EXPERIENCES: 'user/experience',
  QUICK_LINKS: 'expFilters',
  EXPERIENCE: '/user/experience',
  RATINGS_REVIEWS: '/review-rating',
  RESTAURANT_ITEMS: '/user/menu',
  MENU_FILTER: '/user/menu-category',
  MENU_FILTER_DATA: '/user/items',
  OTP_AUTHENTICATION: '/otp-authentication',
  DINER: '/diner',
  USER_DINER: '/user/diner',
  RESTAURANT_AVAILABILITY: '/restaurant-availability',
  USER_APP_DOWNLOAD_LINK: 'https://amealio.page.link/nPwh',
  RECOMMENDED_FOOD: '/recommended-items',
  PROMOTIONAL_VIDEOS: '/user/promotions-Video',
  USERDETAILS: '/user-check',
  PAGESTATS: 'pageStats',
  USER_ORDER: 'user-ordering',
  UPDATE_TRANSACTION: 'updateTransaction',
  TIMESLOTS: 'restaurant-availability',
  USEREXP: '/userExpRequest',
  USERSERICE: 'user-service', //user info
  USEROFFERS: 'user/offers',
  RAZORPAY_ORDER_RECORD: 'razorpay?order=true&user=true',
  RAZORPAY_CUSTOMER_RECORD: 'https://api.razorpay.com/v1/customers',
  RAZORpAY_PAYMENT_RECORD: `razorpay?payments=true&user=true`,
  WALLET_PAYMENT: 'payment/wallet',
  WALLETAMOUNT: 'wallet',
  SOCILALOGIN: 'social-sign-in',
};
