import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SaveButton from '../healthPreferences/SaveButton';
import image from '../../assets/images/IconsandImages/image.png';

const DietaryRestrictions = () => {
  const dietaryOptions = [
    'Hinduism'
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();

  // Toggle option selection
  const handleOptionClick = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((item) => item !== option) 
        : [...prevOptions, option] 
    );
  };

  return (
    <div className="dietary-preferences-page">
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="preferences">
            <h1>Dietary Restrictions</h1>
            <p>
            We respect your dietary choices. Let us know if you have any dietary restrictions, and we'll tailor our recommendations to match your preferences. This way, you'll receive personalized suggestions that align with your dietary needs.            </p>
            <div className="options-container">
              {dietaryOptions.map((option) => (
                <div
                  key={option}
                  className={`option ${selectedOptions.includes(option) ? 'selected' : ''}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option} <span className="plus">+</span>
                </div>
              ))}
            </div>
            <SaveButton
              onClick={() => navigate('/expPreference')}
              active={selectedOptions.length > 0} 
            />
          </div>
        </Col>

        <Col lg={6} md={6} className="image-section">
          <img src={image} alt="Health Preferences" className="profile-image" />
        </Col>
      </Row>
    </div>
  );
};

export default DietaryRestrictions;
