// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth, FacebookAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyDdFnMqK0NlhvCJ2ejR_MlTOPNGbu8BhYQ',
  authDomain: 'amealio-usa.firebaseapp.com',
  projectId: 'amealio-usa',
  storageBucket: 'amealio-usa.firebasestorage.app',
  messagingSenderId: '859203423831',
  appId: '1:859203423831:web:fccc7b6c9d446d69e8dd12',
  measurementId: 'G-K31WH3GH67',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const facebookProvider = new FacebookAuthProvider();

export default app;
