import { Box } from '@mui/material';
import React from 'react';
import { Image } from 'react-bootstrap';

function AmealioLogo() {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Image
        src={require('../../assets/images/IconsandImages/amealiologowithtext.png')}
        width={'120px'}
        style={{ maxWidth: '100%' }}
      />
    </Box>
  );
}

export default AmealioLogo;
