import { Box, Divider, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import { assets } from '../../assets/assets';
import ReusableTextField from '../../components/inputFields/ResuableTextField';
import CountryCodeSelect from '../../components/inputFields/PhoneNumberCountryCodesSelect';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import useApi from '../../common/api/hooks/useApi';
import UAParser from 'ua-parser-js'; // For getting device details
import GoogleSignIn from '../../components/singups/GoogleSignIn';
import { URL } from '../../common/api/urls';

function UserLogin() {
  const [countryCode, setCountryCode] = useState('+91');
  const [deviceDetails, setDeviceDetails] = useState({});
  const { handleRequest, loading } = useApi();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    const parser = new UAParser();
    const device = parser.getDevice();
    const os = parser.getOS();
    const browser = parser.getBrowser();

    const details = {
      deviceId: navigator.userAgent,
      deviceType: device.type || 'desktop',
      deviceName: device.vendor || 'unknown',
      deviceVersion: browser.version,
      deviceUniqId: navigator.userAgent,
      deviceSystemVersion: os.version,
    };

    setDeviceDetails(details);
  }, []);

  const formik = useFormik({
    initialValues: {
      phoneNumber: '',
      country_code: '91',
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required('Phone number is required')
        .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
    }),
    onSubmit: async (values) => {
      setErrorMessage('');

      const otpPayload = {
        strategy: 'phone',
        mobile_number: values.phoneNumber,
        country_code: countryCode,
        deviceId: deviceDetails.deviceId || '',
        deviceType: deviceDetails.deviceType || '',
        deviceVersion: deviceDetails.deviceVersion || '',
        deviceUniqId: deviceDetails.deviceUniqId || '',
        deviceSystemVersion: deviceDetails.deviceSystemVersion || '',
        deviceName: deviceDetails.deviceName || '',
      };

      try {
        await handleRequest('patch', '/otp-authentication', otpPayload, {
          successMessage: 'OTP sent successfully!',
          errorMessage: 'Failed to send OTP. Please try again.',
          onSuccess: (otpResponse) => {
            console.log('OTP response:', otpResponse);

            sessionStorage.setItem(
              'userOtpDetails',
              JSON.stringify(otpResponse)
            );
            sessionStorage.setItem(
              'userOtpPayload',
              JSON.stringify(otpPayload)
            );

            navigate('/enterloginotp');
          },
          onError: (error) => {
            setErrorMessage(error || 'Failed to send OTP. Please try again.');
          },
        });
      } catch (error) {
        console.error('Error during OTP request:', error);
        setErrorMessage(error.message);
      }
    },
  });

  const onSuccessCall = (data, signup) => {
    if (signup) {
      const [firstName, lastName] = (
        data.providerData[0].displayName || ''
      ).split(' ');

      const payload = {
        mobile_number: '',
        email: data.providerData[0].email,
        first_name: firstName,
        last_name: lastName,
        googleid: data.providerData[0].uid,
        password: '',
        profile_pic_google: data.providerData[0].photoURL,
        country_code: '',
        ...deviceDetails,
      };
      handleRequest('post', URL.SOCILALOGIN, payload, {
        onSuccess: (otpResponse) => {
          sessionStorage.setItem(
            'userInformation',
            JSON.stringify(otpResponse)
          );
          navigate('/welcome');
        },
        onError: (error) => console.error('Request failed:', error),
        notify: true,
        successMessage: 'Data saved successfully!',
        errorMessage: 'Failed to save data.',
      });
    }
  };
  return (
    <Box>
      <Grid
        container
        spacing={2}
        px={4}
        sx={{
          padding: {
            xs: '3rem 2rem',
            sm: '3rem 2rem',
            md: '3rem 5rem',
            lg: '3rem 5rem',
            xl: '4rem 6rem',
          },
        }}
      >
        <Grid item xs={12} md={6} py={4} px={2}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Image
              src={require('../../assets/images/IconsandImages/amealiologowithtext.png')}
              width={'120px'}
              style={{ maxWidth: '100%' }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            pt={2}
          >
            <Typography className="f30-800">Welcome</Typography>
            <Typography
              variant="body1"
              color="initial"
              sx={{ display: { xs: 'none', sm: 'none', md: 'inline' } }}
            >
              Language{' '}
              <span
                className="f18-700"
                style={{ color: assets.colors.primary }}
              >
                {' '}
                EN
              </span>
            </Typography>
          </Box>
          <Typography className="f14-500" color={assets.colors.greySubText2}>
            Please Login or Sign up to continue
          </Typography>
          <Box my={2}>
            <GoogleSignIn onSuccess={onSuccessCall} />
            <Image
              src={require('../../assets/images/socialmedia/Facebook.png')}
              width={'40px'}
              className="grey-round-border mx-2"
              style={{ maxWidth: '100%' }}
            />
            <Image
              src={require('../../assets/images/socialmedia/apple.png')}
              width={'40px'}
              className="grey-round-border mx-2"
              style={{ maxWidth: '100%' }}
            />
          </Box>
          {/* <GoogleLoginButton />
          <FacebookLoginButton /> */}

          <Divider>OR</Divider>

          {errorMessage && (
            <Typography color="error" variant="body2" sx={{ mb: 2 }}>
              {errorMessage}
            </Typography>
          )}

          <Typography className="f24-800 mt-3 my-2">Login</Typography>

          <form onSubmit={formik.handleSubmit}>
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={4} md={2} sx={{ paddingTop: '0px' }}>
                  <CountryCodeSelect
                    countryCode={countryCode}
                    onCountryChange={(value) => setCountryCode(value)}
                    required
                    size="small"
                  />
                </Grid>
                <Grid item xs={8} md={10} sx={{ paddingTop: '0px' }}>
                  <ReusableTextField
                    placeholder={'Phone Number*'}
                    size="small"
                    name="phoneNumber"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phoneNumber}
                    error={
                      formik.touched.phoneNumber &&
                      Boolean(formik.errors.phoneNumber)
                    }
                    helperText={
                      formik.touched.phoneNumber && formik.errors.phoneNumber
                    }
                  />
                </Grid>
              </Grid>

              <Grid item xs={12} my={2}>
                <PrimaryButton
                  type="submit"
                  size="large"
                  fullWidth
                  disabled={loading || formik.isSubmitting}
                >
                  {loading || formik.isSubmitting ? 'Submitting...' : 'Login'}
                </PrimaryButton>
              </Grid>

              <Box
                sx={{
                  display: { xs: 'block', sm: 'bloack', md: 'flex' },
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
                pt={2}
              >
                <Typography className="f16-500">
                  Don't have an account?{' '}
                  <span
                    style={{ color: assets.colors.primary, cursor: 'pointer' }}
                    className="f16-700"
                    onClick={() => navigate('/signup')}
                  >
                    Sign Up
                  </span>
                </Typography>
                <Typography className="f16-500">
                  Need{' '}
                  <span
                    style={{ color: assets.colors.primary }}
                    className="f16-700 mx-1"
                  >
                    Help
                  </span>
                </Typography>
              </Box>
            </Box>
          </form>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          p={4}
        >
          <Image
            src={require('../../assets/images/IconsandImages/splash1.png')}
            style={{
              width: '560px',
              height: '600px',
              padding: '20px 20px 4px 20px',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserLogin;
