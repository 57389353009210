import React, { useState, useEffect, useRef } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import {
  getCountries,
  getCountryCallingCode,
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { Box } from '@mui/material';

const CountryCodeSelect = ({
  countryCode,
  onCountryChange,
  required,
  fullWidth,
  disabled,
  variant,
  sx,
  size,
}) => {
  const [open, setOpen] = useState(false); // State to track if dropdown is open
  const [width, setWidth] = useState(0); // State to track width of the selected value
  const textRef = useRef(null); // Ref to measure text width

  // Update the width of the select box based on the selected value
  useEffect(() => {
    if (textRef.current) {
      setWidth(textRef.current.offsetWidth + 80); // Add some padding for better appearance
    }
  }, [countryCode]);

  return (
    <Box display="flex" alignItems="center">
      <span
        ref={textRef}
        style={{ visibility: 'hidden', position: 'absolute' }}
      >
        {countryCode.replace('+', '')}
      </span>
      <TextField
        select
        value={countryCode}
        onChange={(e) => onCountryChange(e.target.value)}
        required={required}
        variant={variant}
        disabled={disabled}
        fullWidth={fullWidth}
        className="my-2"
        size={size || 'medium'}
        sx={{ width: width, ...sx }} // Set the width dynamically
        onOpen={() => setOpen(true)} // Set open state to true when dropdown is opened
        onClose={() => setOpen(false)} // Set open state to false when dropdown is closed
        SelectProps={{
          renderValue: () => (open ? countryCode : countryCode), // Show only code when not open
        }}
      >
        {getCountries().map((country) => (
          <MenuItem key={country} value={'+' + getCountryCallingCode(country)}>
            {en[country]} (+{getCountryCallingCode(country)})
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

// Default Props
CountryCodeSelect.defaultProps = {
  required: false,
  fullWidth: true,
  disabled: false,
  variant: 'outlined',
  sx: {},
};

// Prop Types for validation
CountryCodeSelect.propTypes = {
  countryCode: PropTypes.string.isRequired,
  onCountryChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['outlined', 'filled', 'standard']),
  sx: PropTypes.object,
};

export default CountryCodeSelect;
