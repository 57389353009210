import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import ProfilePicture from './ProfilePicture';
import ProfileSetupButtons from './ProfileSetupBttns';
import useWindowWidth from '../../common/hooks/useWindowWidth';
import image from '../../assets/images/IconsandImages/image.png'; // Replace with the actual path
import { screenPadding } from '../../assets/muiClasses';
import { Box, Grid } from '@mui/material';

const FirstImpressions = () => {
  const windowWidth = useWindowWidth();

  return (
    // <div className="profile-setup-container">
    //   <Row className={`center-row ${windowWidth <= 768 ? 'center-mobile' : ''}`}>
    //     <Col lg={6} md={6} sm={12} xs={12} className="profile">
    //       <ProfilePicture />
    //       <ProfileSetupButtons />
    //     </Col>
    //     {windowWidth > 1024 && (
    //       <Col lg={6} md={6} className="image-section">
    //         <img src={image} alt="Decorative" className="profile-image" />
    //       </Col>
    //     )}
    //   </Row>
    // </div>
    <Box>
      <Grid container spacing={2} px={4} sx={{ padding: screenPadding }}>
        <Grid item xs={12} md={6} py={4} px={2}>
          <ProfilePicture />
          <ProfileSetupButtons />
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          p={4}
        >
          <Image
            src={require('../../assets/images/IconsandImages/Frame.png')}
            style={{
              width: '560px',
              height: '600px',
              padding: '20px 20px 4px 20px',
            }}
            alt="Frame"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FirstImpressions;
