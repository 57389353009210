import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';

function Header() {
  return (
    <Container fluid> {/* Fluid container for full width */}
      <Navbar expand="lg" className="bg-white navbar-light" >
        <Container className="d-flex justify-content-between align-items-center">
          
          {/* Left side (Location) */}
          <div className="d-flex align-items-center text-white" style={{ flexBasis: '70%' }}> {/* Set flex-basis to 70% to expand left side */}
            <img
              src={require('../../assets/images/IconsandImages/Group 47762.png')} 
              alt="Location"
              width="20"
              height="25"
              className="mr-2"
            />
            <div>
              <span className="font-weight-bold" style={{ fontSize: '0.9rem', color: 'black' }}>Amanora Mall</span>
              <p className="mb-0 text-secondary" style={{ fontSize: '0.700rem' }}>Set or change your current location</p>
            </div>
          </div>

          {/* Right side (User and Notification Icons) */}
          <div className="d-flex align-items-center" style={{ flexBasis: '30%', justifyContent: 'flex-end' }}> {/* Set flex-basis to 30% */}
            <img
              src={require('../../assets/images/IconsandImages/Group 87684.png')} // User Icon
              alt="User"
              width="22"
              height="22"
              className="mx-2"
            />
            <img
              src={require('../../assets/images/IconsandImages/Path 27057.png')} // Notification Icon
              alt="Notifications"
              width="21"
              height="21"
              className="mx-2"
            />
          </div>

        </Container>
      </Navbar>
    </Container>
  );
}

export default Header;
