import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SaveButton from './SaveButton';
import image from '../../assets/images/IconsandImages/image.png';

const DietaryPreferences = () => {
  const dietaryOptions = [
    'Low Carb', 'Lactose Free', 'Vegan', 'Gluten Free',
    'Protein Rich', 'Jain', 'Keto'
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();

  // Toggle option selection
  const handleOptionClick = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((item) => item !== option) 
        : [...prevOptions, option] 
    );
  };

  return (
    <div className="dietary-preferences-page">
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="preferences">
            <h1>Dietary Preferences</h1>
            <p>
              Your taste matters. Share your dietary preferences with us, and we'll provide recommendations that suit your unique tastes. Your choices help us make meal suggestions that cater to your culinary desires.
            </p>
            <div className="options-container">
              {dietaryOptions.map((option) => (
                <div
                  key={option}
                  className={`option ${selectedOptions.includes(option) ? 'selected' : ''}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option} <span className="plus">+</span>
                </div>
              ))}
            </div>
            <SaveButton
            onClick={() => navigate('/foodiedays')}
            active={selectedOptions.length > 0}
            style={{
            position: 'absolute',
            bottom:'40px'              
              }}
          />
          </div>
        </Col>

        <Col lg={6} md={6} className="image-section">
          <img src={image} alt="Health Preferences" className="profile-image" />
        </Col>
      </Row>
    </div>
  );
};

export default DietaryPreferences;
