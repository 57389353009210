import { useState } from 'react';
import useApi from '../../../common/api/hooks/useApi';

const useLocationData = () => {
  const { handleRequest, loading } = useApi();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  // Function to get all countries
  const getAllCountries = async () => {
    try {
      const response = await handleRequest(
        'get',
        '/country-state-city?country=true',
        null,
        {
          successMessage: 'Countries fetched successfully',
          onSuccess: setCountries,
        }
      );
      return response;
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  // Function to get all states from a specific country
  const getAllStatesFromCountry = async (countryCode) => {
    try {
      const response = await handleRequest(
        'get',
        `/country-state-city?state=true&country_id=${countryCode}`,
        null,
        {
          successMessage: 'States fetched successfully',
          onSuccess: setStates,
        }
      );
      return response;
    } catch (error) {
      console.error('Error fetching states:', error);
    }
  };

  // Function to get all cities from a specific state
  const getAllCitiesFromState = async (stateCode) => {
    try {
      const response = await handleRequest(
        'get',
        `/country-state-city?city=true&state_id=${stateCode}`,
        null,
        {
          successMessage: 'Cities fetched successfully',
          onSuccess: setCities,
        }
      );
      return response;
    } catch (error) {
      console.error('Error fetching cities:', error);
    }
  };

  return {
    getAllCountries,
    getAllStatesFromCountry,
    getAllCitiesFromState,
    countries,
    states,
    cities,
    loading,
  };
};

export default useLocationData;
