import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';

const GoogleLoginButton = () => {
  const login = useGoogleLogin({
    onSuccess: async (response) => {
      console.log('Google OAuth Success:', response);
      const accessToken = response.access_token;

      // Fetch user details
      try {
        const userInfo = await fetchGoogleUserInfo(accessToken);
        console.log('User Info:', userInfo);

        // Store user details in session storage
        sessionStorage.setItem('googleUser', JSON.stringify(userInfo));

        // Store access token in session storage (optional)
        sessionStorage.setItem('googleAccessToken', accessToken);
      } catch (error) {
        console.error('Failed to fetch user info:', error);
      }
    },
    onError: () => {
      console.log('Google OAuth Failed');
    },
  });

  return (
    <button onClick={() => login()} style={buttonStyle}>
      Login with Google
    </button>
  );
};

const fetchGoogleUserInfo = async (accessToken) => {
  try {
    const response = await fetch(
      'https://www.googleapis.com/oauth2/v3/userinfo',
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    if (!response.ok) {
      throw new Error('Failed to fetch user information');
    }
    const userInfo = await response.json();
    return userInfo;
  } catch (error) {
    console.error('Error fetching user info:', error);
    throw error;
  }
};

const buttonStyle = {
  padding: '10px 20px',
  backgroundColor: '#4285F4',
  color: '#fff',
  border: 'none',
  borderRadius: '4px',
  cursor: 'pointer',
};

export default GoogleLoginButton;
