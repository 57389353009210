import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, Typography, TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CountryCodeSelect from '../../components/inputFields/PhoneNumberCountryCodesSelect';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { assets } from '../../assets/assets';
import useApi from '../../common/api/hooks/useApi';
import { useNotification } from '../../components/alerts/NotificationContext';
import { URL } from '../../common/api/urls';
import SuccessModal from '../../components/alerts/SuccessModal';
import GoBackButton from '../../components/buttons/GoBack';
import { Navigate, useNavigate } from 'react-router-dom';

function LoginOtpScreen() {
  const navigate = useNavigate();
  const [otp, setOtp] = useState(Array(6).fill(''));
  const otpRefs = useRef([]);
  const [canResend, setCanResend] = useState(false);
  const [timer, setTimer] = useState(45);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [apiError, setApiError] = useState('');
  const SingUpDetails = JSON.parse(sessionStorage.getItem('userOtpDetails'));
  const savedOtpPayload = JSON.parse(sessionStorage.getItem('userOtpPayload'));
  const { handleRequest } = useApi();
  const { showNotification } = useNotification();
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(
    SingUpDetails?.mobile_number || ''
  );
  const [countryCode, setCountryCode] = useState('+91');

  useEffect(() => {
    let countdown = null;
    if (timer > 0) {
      countdown = setTimeout(() => setTimer(timer - 1), 1000);
    } else {
      setCanResend(true);
    }
    return () => clearTimeout(countdown);
  }, [timer]);

  const formik = useFormik({
    initialValues: {
      otp: Array(6).fill(''),
    },
    validationSchema: Yup.object({
      otp: Yup.array().test(
        'otp-length',
        'OTP must be 6 digits',
        (value) => value.join('').length === 6
      ),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: async (values) => {
      const otpCode = values.otp.join('');
      console.log('OTP Entered:', otpCode);

      const otpPayload = {
        strategy: 'phone',
        mobile_number: phoneNumber,
        country_code: countryCode.replace('+', ''),
        otp: otpCode,
      };

      try {
        await handleRequest(
          'get',
          `${URL.OTP_AUTHENTICATION}?user_id=${SingUpDetails?.User}&OTP=${otpCode}`,
          null,
          {
            successMessage: 'OTP verified successfully!',
            errorMessage: 'Invalid OTP. Please try again.',
            onSuccess: (otpResponse) => {
              sessionStorage.setItem(
                'userInformation',
                JSON.stringify(otpResponse)
              );
              showNotification('OTP Verified Successfully!', 'success');
              setSuccessModalOpen(true);
              navigate('/welcome');
              setApiError('');
            },
            onError: () => {
              setApiError('Invalid OTP. Please try again.');
            },
          }
        );
      } catch (error) {
        console.error('Error during OTP verification:', error);
        setApiError('Invalid OTP. Please try again.');
      }
    },
  });

  const handleOtpChange = (index, value) => {
    if (/^\d$/.test(value) || value === '') {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);
      formik.setFieldValue(`otp[${index}]`, value);

      if (value && index < otpRefs.current.length - 1) {
        otpRefs.current[index + 1].focus();
      }
    }
  };

  const handleBackspace = (index, e) => {
    if (e.key === 'Backspace') {
      const updatedOtp = [...otp];
      updatedOtp[index] = '';
      setOtp(updatedOtp);
      formik.setFieldValue(`otp[${index}]`, '');

      if (index > 0) {
        otpRefs.current[index - 1].focus();
      }
    }
  };

  const handleResend = async () => {
    setTimer(45);
    setCanResend(false);
    setApiError('');

    const resendOtpPayload = {
      ...savedOtpPayload,
      otp: '',
    };

    try {
      await handleRequest('patch', '/otp-authentication', resendOtpPayload, {
        successMessage: 'OTP resent successfully!',
        errorMessage: 'Failed to resend OTP. Please try again.',
      });
      showNotification('OTP Resent Successfully!', 'success');
    } catch (error) {
      console.error('Error during OTP resend:', error);
      setApiError('Failed to resend OTP. Please try again.');
    }
  };

  const handleEditPhoneNumber = (values) => {
    const { phoneNumber: newPhoneNumber } = values;
    setPhoneNumber(newPhoneNumber);
    setIsEditingPhoneNumber(false);
    setTimer(45);
    setCanResend(false);
  };

  const phoneFormik = useFormik({
    initialValues: {
      phoneNumber: phoneNumber,
    },
    validationSchema: Yup.object({
      phoneNumber: Yup.string()
        .required('Phone number is required')
        .matches(/^\d{10}$/, 'Phone number must be 10 digits'),
    }),
    onSubmit: handleEditPhoneNumber,
  });

  return (
    <Box>
      <Grid
        container
        spacing={2}
        px={4}
        sx={{ padding: { xs: '3rem 2rem', md: '3rem 5rem' } }}
      >
        <Grid item xs={12} md={6} py={4}>
          <GoBackButton route="/userlogin" label="Go Back" />

          <Box>
            <Typography
              className="f30-800"
              sx={{ fontWeight: 'bold', fontSize: '24px' }}
            >
              Enter OTP
            </Typography>

            <Typography
              className="f16-500"
              color="textSecondary"
              sx={{ marginBottom: '16px' }}
            >
              Please enter the OTP sent to your phone number:{' '}
              <span style={{ fontWeight: 'bold' }}>
                {' '}
                {savedOtpPayload?.mobile_number}
              </span>
              <span
                onClick={() => setIsEditingPhoneNumber(true)}
                style={{
                  color: assets.colors.primary,
                  cursor: 'pointer',
                  marginLeft: '8px',
                }}
              >
                Edit
              </span>
            </Typography>

            <form onSubmit={formik.handleSubmit}>
              <Box display="flex" justifyContent="start" my={2} gap={1}>
                {otp.map((value, index) => (
                  <TextField
                    key={index}
                    value={value}
                    onChange={(e) => handleOtpChange(index, e.target.value)}
                    onKeyDown={(e) => handleBackspace(index, e)}
                    inputRef={(el) => (otpRefs.current[index] = el)}
                    inputProps={{
                      maxLength: 1,
                      style: { textAlign: 'center', fontSize: '1.5rem' },
                    }}
                    sx={{ width: '3rem', height: '4rem' }}
                    variant="outlined"
                    error={Boolean(apiError)}
                  />
                ))}
              </Box>

              {apiError && (
                <Typography color="error" variant="body2" sx={{ mb: 2 }}>
                  {apiError}
                </Typography>
              )}

              <Typography
                variant="body2"
                color={assets.colors.primary}
                sx={{
                  cursor: canResend ? 'pointer' : 'default',
                  marginBottom: '16px',
                }}
                onClick={canResend ? handleResend : null}
              >
                {canResend ? 'Resend OTP' : `Resend OTP in ${timer}s`}
              </Typography>

              <PrimaryButton
                type="submit"
                size="large"
                fullWidth
                sx={{ marginTop: '24px' }}
              >
                Submit
              </PrimaryButton>
            </form>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          p={4}
        >
          <img
            src={require('../../assets/images/IconsandImages/splash1.png')}
            alt="Splash Image"
            style={{
              width: '560px',
              height: '600px',
              padding: '20px 20px 4px 20px',
            }}
          />
        </Grid>
      </Grid>

      <SuccessModal
        open={successModalOpen}
        handleClose={() => setSuccessModalOpen(false)}
        mainImage={require('../../assets/images/IconsandImages/celebration.png')}
        icon={
          <img
            src={require('../../assets/images/IconsandImages/greenrightcircular.png')}
            alt="Success Icon"
          />
        }
        title="Woah!!!"
        message="Successfully Logged-In"
      />
    </Box>
  );
}

export default LoginOtpScreen;
