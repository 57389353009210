import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import AmealioLogo from '../../components/reusable/AmealioLogo';
import { Image } from 'react-bootstrap';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { assets } from '../../assets/assets';

function UserPreferences() {
  const Screenpadding = {
    xs: '2rem 2rem',
    sm: '2rem 2rem',
    md: '2rem 5rem',
    lg: '2rem 5rem',
    xl: '2rem 6rem',
  };
  return (
    <Box>
      <Grid
        container
        spacing={2}
        px={4}
        sx={{
          padding: Screenpadding,
        }}
      >
        <Grid item xs={12} md={6} py={4} px={2}>
          <AmealioLogo />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography className="f30-800" mt={2}>
              Would Love to know You
            </Typography>

            <Typography
              className="f18-500"
              my={2}
              sx={{ textAlign: 'center', color: assets.colors.greySubtext }}
            >
              The Information ypu are about to fill is for us to provide a
              better experience
            </Typography>
            <Grid item xs={12} md={6}>
              <Image
                src={require('../../assets/images/preferences/personalInfo.png')}
                width={300}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Image
                src={require('../../assets/images/preferences/healthPreferences.png')}
                width={300}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Image
                src={require('../../assets/images/preferences/healthRestrict.png')}
                width={300}
                className="ml-2"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Image
                src={require('../../assets/images/preferences/ExperienceNew.png')}
                width={300}
              />
            </Grid>

            <PrimaryButton fullWidth>Next</PrimaryButton>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          p={4}
        >
          <Image
            src={require('../../assets/images/IconsandImages/Frame.png')}
            style={{
              width: '560px',
              height: '600px',
              padding: '20px 20px 4px 20px',
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserPreferences;
