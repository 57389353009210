import React from 'react';
import Experiencebanner from '../../assets/images/IconsandImages/Experiencebanner.png';

const ExperienceBanner = () => {
  return (
    <div>
      <span style={{ fontSize: '20px', fontWeight: 'bold', paddingTop:'-50px', marginTop:'20px',marginBottom:'10px',marginLeft:'15px'}}>Experience</span>
    <div
      style={{
        backgroundImage: `url(${Experiencebanner})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '10px',
        padding: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        height: '200px', 
        flexDirection: 'column', 
        marginTop: '20px',
        marginBottom:'20px'
      }}
    >           
    </div>
    </div>
  );
};

export default ExperienceBanner;
