import {
  Box,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import { Image } from 'react-bootstrap';
import useLocationData from '../../components/locations/getCountryStateCity/LocationData';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { URL } from '../../common/api/urls';
import useApi from '../../common/api/hooks/useApi';
import { useNavigate } from 'react-router-dom';
import { screenPadding } from '../../assets/muiClasses';

function UserLocation() {
  const userDetails = JSON.parse(sessionStorage.getItem('userInformation'));
  const navigate = useNavigate();
  const {
    getAllCountries,
    getAllStatesFromCountry,
    getAllCitiesFromState,
    countries,
    states,
    cities,
  } = useLocationData();

  const { handleRequest, loading } = useApi();

  const [location, setLocation] = useState({
    countryId: '',
    countryName: '',
    stateId: '',
    stateName: '',
    cityId: '',
    cityName: '',
  });

  useEffect(() => {
    getAllCountries();
  }, []);

  useEffect(() => {
    if (location.countryId) getAllStatesFromCountry(location.countryId);
  }, [location.countryId]);

  useEffect(() => {
    if (location.stateId) getAllCitiesFromState(location.stateId);
  }, [location.stateId]);

  const handleChange = (field, value, nameField, name) => {
    setLocation((prev) => ({
      ...prev,
      [field]: value,
      [nameField]: name,
      ...(field === 'countryId' && {
        stateId: '',
        stateName: '',
        cityId: '',
        cityName: '',
      }),
      ...(field === 'stateId' && { cityId: '', cityName: '' }),
    }));
  };

  const handleNextClick = async () => {
    try {
      await handleRequest(
        'patch',
        `${URL.USERSERICE}/${userDetails?._id}`,
        {
          country: location.countryName,
          state: location.stateName,
          city: location.cityName,
          profile_percentage: '50',
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: userDetails?.accessToken,
          },
          onSuccess: navigate('/profile'),
        }
      );
    } catch (error) {
      console.error('API error:', error);
    }
  };

  return (
    <Box>
      <Grid container spacing={2} px={4} sx={{ padding: screenPadding }}>
        <Grid item xs={12} md={6} py={4} px={2}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography className="f30-800" my={2}>
              Your Location
            </Typography>
            <Image
              src={require('../../assets/images/IconsandImages/locationcross.png')}
              width={50}
              alt="Location Icon"
            />
            <Typography className="f18-500" my={2}>
              Auto Detected My Location
            </Typography>

            {/* Country Select */}
            <FormControl fullWidth margin="normal">
              <InputLabel>Country</InputLabel>
              <Select
                value={location.countryId}
                onChange={(e) => {
                  const country = countries.find(
                    (c) => c.id === e.target.value
                  );
                  handleChange(
                    'countryId',
                    e.target.value,
                    'countryName',
                    country?.name || ''
                  );
                }}
                label="Country"
              >
                {countries.map((country) => (
                  <MenuItem key={country.id} value={country.id}>
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* State Select */}
            <FormControl
              fullWidth
              margin="normal"
              disabled={!location.countryId}
            >
              <InputLabel>State</InputLabel>
              <Select
                value={location.stateId}
                onChange={(e) => {
                  const state = states.find((s) => s.id === e.target.value);
                  handleChange(
                    'stateId',
                    e.target.value,
                    'stateName',
                    state?.name || ''
                  );
                }}
                label="State"
              >
                {states.map((state) => (
                  <MenuItem key={state.id} value={state.id}>
                    {state.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {/* City Select */}
            <FormControl fullWidth margin="normal" disabled={!location.stateId}>
              <InputLabel>City</InputLabel>
              <Select
                value={location.cityId}
                onChange={(e) => {
                  const city = cities.find((c) => c.id === e.target.value);
                  handleChange(
                    'cityId',
                    e.target.value,
                    'cityName',
                    city?.name || ''
                  );
                }}
                label="City"
              >
                {cities.map((city) => (
                  <MenuItem key={city.id} value={city.id}>
                    {city.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Typography className="f16-500" my={2} sx={{ textAlign: 'center' }}>
              We need your basic location information to better serve you and
              improve your experience
            </Typography>

            <PrimaryButton
              fullWidth
              onClick={handleNextClick}
              disabled={loading}
            >
              {loading ? 'Processing...' : 'Next'}
            </PrimaryButton>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={6}
          sx={{ display: { xs: 'none', md: 'block' } }}
          p={4}
        >
          <Image
            src={require('../../assets/images/IconsandImages/Frame.png')}
            style={{
              width: '560px',
              height: '600px',
              padding: '20px 20px 4px 20px',
            }}
            alt="Frame"
          />
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserLocation;
