// SaveButton.js
import React from 'react';

const SaveButton = ({ onClick, active }) => {
  return (
    <button
      onClick={onClick}
      className={`save-button ${active ? 'active' : ''}`} // Apply active class if active prop is true
      disabled={!active} // Disable button if no options selected
    >
      Save & Continue
    </button>
  );
};

export default SaveButton;
