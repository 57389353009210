import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import FoodandDrink from '../../assets/images/IconsandImages/FoodandDrink.png';
import Outingpreferences from '../../assets/images/IconsandImages/Outingpreferences.png';
import Hobbies from '../../assets/images/IconsandImages/Hobbies.png';
import Travel from '../../assets/images/IconsandImages/Travel.png';
import Music from '../../assets/images/IconsandImages/Music.png';
import Entertainment from '../../assets/images/IconsandImages/Entertainment.png';
import Sports from '../../assets/images/IconsandImages/Sports.png';
import image from '../../assets/images/IconsandImages/image.png';




const SocialPreferences = () => {
  const navigate = useNavigate();

  const handleSave = () => {
      navigate('/profileSetupMenu');
  };

  return (
      <div className="SocialPreferences-page">
          <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                  <div className="preferences">
                      <h1>Social Preferences</h1>
                      <p>Please let us know about your particular preferences.</p>

                      <div className="preference-section">
                          <img src={FoodandDrink} alt="Food and Drink" className="preference-image" />
                          <h2>Food and Drink</h2>
                       </div>

                      <div className="preference-section">
                          <img src={Outingpreferences} alt="Outing Preferences" className="preference-image" />
                          <h2>Outing Preferences</h2>
                          
                      </div>

                      <div className="preference-section">
                          <img src={Hobbies} alt="Hobbies" className="preference-image" />
                          <h2>Hobbies</h2>
                      </div>

                      <div className="preference-section">
                          <img src={Travel} alt="Travel" className="preference-image" />
                          <h2>Travel</h2>
                      </div>

                      <div className="preference-section">
                          <img src={Music} alt="Music" className="preference-image" />
                          <h2>Music</h2>
                      </div>

                      <div className="preference-section">
                          <img src={Entertainment} alt="Entertaiment" className="preference-image" />
                          <h2>Entertainment</h2>
                      </div>

                      <div className="preference-section">
                          <img src={Sports} alt="Sports" className="preference-image" />
                          <h2>Sports</h2>
                      </div>

                      <button className="save-button" onClick={handleSave}>
                          Save & Continue
                      </button>
                  </div>
              </Col>

              <Col lg={6} md={6} className="image-section">
                  <img src={image} alt="Profile" className="profile-image" />
              </Col>
          </Row>
      </div>
  );
};

export default SocialPreferences;