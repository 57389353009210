import React from 'react';
import { Col, Row, Image, Button } from 'react-bootstrap';

const RestaurantListWeb = () => {
  const restaurants = [
    { name: 'Xenia', rating: 2.2, distance: '1.2 km' },
    { name: 'The Chinese Wok', rating: 3.5, distance: '2.5 km' },
    { name: 'Punjab Grill', rating: 4.0, distance: '3.2 km' },
  ];

  return (
    <div>
      <div style={{ marginBottom: '20px',marginTop:'25px' }}>
        <h3 style={{fontSize: '20px', fontWeight: 'bold'}}>Always Find a Place to Eat</h3>
        <p style={{ fontSize: '14px', color: '#300' }}>
          Restaurants are unregistered, do not have any features yet.
        </p>
        <p style={{ fontSize: '10px', color: '#666' }}>
          Nominate and refer the restaurants to earn rewards. <a href="#">Learn more</a>
        </p>
      </div>

      <div className="d-flex flex-wrap justify-content-between" style={{ gap: '20px' }}>
        {restaurants.map((restaurant, index) => (
          <div
            key={index}
            className="restaurant_title_card"
            style={{
              position: 'relative',
              borderRadius: '15px',
              border: '1px solid lightgray',
              backgroundColor: 'white',
              padding: '15px',
              width: '100%',
              maxWidth: '350px', 
              margin: '0', 
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            }}
          >
            
            <Row className="p-1 pb-3 m-0">
              <Col xs={2} className="d-flex align-items-center">
                <Image
                  src={require('../../assets/images/IconsandImages/Frame 1000007033.png')}
                  alt="restaurantIcon"
                  className="restaurant_icon"
                  style={{ borderRadius: '50%', width: '40px', height: '40px' }}
                />
              </Col>
              <Col xs={10} className="d-flex flex-column justify-content-center">
                <div style={{ fontWeight: 'bold', fontSize: '16px', color: '#000' }}>
                  {restaurant.name}
                </div>
                <div style={{ display: 'flex', alignItems: 'center', fontSize: '12px', color: '#666' }}>
                  <Image
                    src={require('../../assets/images/IconsandImages/star.png')}
                    alt="starRating"
                    className="star_rating"
                    style={{ width: '14px', marginRight: '5px' }}
                  />
                  <span>{restaurant.rating}</span>
                  <span className="mx-1">|</span>
                  <span>{restaurant.distance}</span>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xs={12}>
                <p className="text-muted" style={{ fontSize: '14px' }}>
                  Please Sign up or Login to nominate a restaurant to Amealio.
                </p>
              </Col>
            </Row>

            <Row className="d-flex align-items-center justify-content-between">
              <Col xs={8}>
              <Button
                className="text-white"
                style={{
                  backgroundColor: '#EE3A23',
                  borderRadius: '13px',
                  fontSize: '14px',
                  width: '70%',
                  border: 'none', 
                }}
              >
                Login / Sign Up
              </Button>
              </Col>
              <Col xs={4} className="text-right">
                <Image
                  src={require('../../assets/images/IconsandImages/Frame 1000007037.png')}
                  alt="directionIcon"
                  style={{ width: '24px', height: '24px', marginLeft: '55px' }}
                />
              </Col>
            </Row>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RestaurantListWeb;
