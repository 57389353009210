import React from 'react';
import Rectangle39721 from '../../assets/images/IconsandImages/Rectangle 39721.png'; 
import filterarrow from '../../assets/images/IconsandImages/filterarrow.png'; 

const HomePageFilterBttn = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        maxWidth: '500px', // Reduced maxWidth to make layout more compact
        margin: '2px auto',
      }}
    >
      <button 
        style={{
          display: 'flex',              
          alignItems: 'center',         
          backgroundImage: `url(${Rectangle39721})`, 
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          borderRadius: '15px',         
          width: '80px',               
          height: '30px',              
          color: '#333',                
          cursor: 'pointer',
          padding: '0 10px',           
          fontSize: '12px',         
          justifyContent: 'space-between', 
        }}
      >
        <span>Sort by</span>
        <img 
          src={filterarrow} 
          alt="Arrow" 
          style={{ width: '8px', height: '7px' }} // Reduced icon size
        />
      </button>

      <button 
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundImage: `url(${Rectangle39721})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          borderRadius: '15px',
          width: '80px',
          height: '30px',
          color: '#333',
          cursor: 'pointer',
          padding: '0 10px',
          fontSize: '12px',
          justifyContent: 'space-between',
        }}
      >
        <span>Services</span>
        <img 
          src={filterarrow} 
          alt="Arrow" 
          style={{ width: '8px', height: '7px' }}
        />
      </button>

      <button 
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundImage: `url(${Rectangle39721})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          borderRadius: '15px',
          width: '80px',
          height: '30px',
          color: '#333',
          cursor: 'pointer',
          padding: '0 10px',
          fontSize: '12px',
          justifyContent: 'space-between',
        }}
      >
        <span>Cuisines</span>
        <img 
          src={filterarrow}
          alt="Arrow" 
          style={{ width: '8px', height: '7px' }}
        />
      </button>

      <button 
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundImage: `url(${Rectangle39721})`,
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          border: 'none',
          borderRadius: '15px',
          width: '110px',                // Slightly wider for text to fit
          height: '30px',
          color: '#333',
          cursor: 'pointer',
          padding: '0 10px',
          fontSize: '12px',
          justifyContent: 'space-between',
        }}
      >
        <span>Cost for two</span>
        <img 
          src={filterarrow} 
          alt="Arrow" 
          style={{ width: '8px', height: '7px' }}
        />
      </button>
    </div>
  );
};

export default HomePageFilterBttn;
