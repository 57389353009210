import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import SaveButton from '../healthPreferences/SaveButton';
import image from '../../assets/images/IconsandImages/image.png';

const ExperiencePreference = () => {
  const dietaryOptions = [
    'Birthday', 'Anniversary', 'Chef Tour', 'Candle Light',
    'Brunches', 'Girls evening', 'Date', 'Date night'
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);
  const navigate = useNavigate();

  const handleOptionClick = (option) => {
    setSelectedOptions((prevOptions) =>
      prevOptions.includes(option)
        ? prevOptions.filter((item) => item !== option)
        : [...prevOptions, option]
    );
  };

  const handleSave = () => {
    navigate('/profileSetupMenu'); 
  };

  return (
    <div className="foodie-days-page">
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <div className="preferences">
            <h1>Experience Preference</h1>
            <p>
            Please select your favourite experiences for a better personalization
            </p>
            <div className="options-container">
              {dietaryOptions.map((option) => (
                <div
                  key={option}
                  className={`option ${selectedOptions.includes(option) ? 'selected' : ''}`}
                  onClick={() => handleOptionClick(option)}
                >
                  {option} <span className="plus">+</span>
                </div>
              ))}
            </div>
            <SaveButton
              onClick={handleSave}
              active={selectedOptions.length > 0}
            />
          </div>
        </Col>

        <Col lg={6} md={6} className="image-section">
          <img src={image} alt="Health Preferences" className="profile-image" />
        </Col>
      </Row>
    </div>
  );
};

export default ExperiencePreference;
