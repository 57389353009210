import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import male_icon from '../../assets/images/IconsandImages/male_icon.png';
import female_icon from '../../assets/images/IconsandImages/female_icon.png';
import others_icon from '../../assets/images/IconsandImages/others_icon.png';
import cantsay_icon from '../../assets/images/IconsandImages/cantsay_icon.png';
import AnniversaryIcon from '../../assets/images/IconsandImages/AnniversaryIcon.png';
import BirthdayIcon from '../../assets/images/IconsandImages/BirthdayIcon.png';
import OthersIcon from '../../assets/images/IconsandImages/OthersIcon.png';
import { Typography } from '@mui/material';

function ProfileSetupButtons() {
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedSpecialDay, setSelectedSpecialDay] = useState(null);
  const navigate = useNavigate();

  const handleGenderSelection = (gender) => {
    setSelectedGender(gender);
  };

  const handleSpecialDaySelection = (specialDay) => {
    setSelectedSpecialDay(specialDay);
  };

  const isContinueEnabled = selectedGender && selectedSpecialDay;

  const handleContinue = () => {
    if (isContinueEnabled) {
      //navigate('/profileSetupMenu');
    }
  };

  return (
    <div className="profile-setup-buttons">
      <div className="section">
        <Typography className="f20-800" gutterBottom>
          Choose Your Gender{' '}
        </Typography>
        <div className="buttons-row">
          <button
            className={`option-button ${
              selectedGender === 'Male' ? 'selected' : ''
            }`}
            onClick={() => handleGenderSelection('Male')}
          >
            <img src={male_icon} alt="Male" /> Male
          </button>
          <button
            className={`option-button ${
              selectedGender === 'Female' ? 'selected' : ''
            }`}
            onClick={() => handleGenderSelection('Female')}
          >
            <img src={female_icon} alt="Female" /> Female
          </button>
          <button
            className={`option-button ${
              selectedGender === 'Other' ? 'selected' : ''
            }`}
            onClick={() => handleGenderSelection('Other')}
          >
            <img src={others_icon} alt="Other" /> Other
          </button>
          <button
            className={`option-button ${
              selectedGender === 'CantSay' ? 'selected' : ''
            }`}
            onClick={() => handleGenderSelection('CantSay')}
          >
            <img src={cantsay_icon} alt="Can't Say" /> Can't Say
          </button>
        </div>
      </div>
      <div className="section">
        <Typography className="f20-800" gutterBottom>
          Special Days{' '}
        </Typography>
        <div className="buttons-row2">
          <button
            className={`option-button ${
              selectedSpecialDay === 'Anniversary' ? 'selected' : ''
            }`}
            onClick={() => handleSpecialDaySelection('Anniversary')}
          >
            <img src={AnniversaryIcon} alt="Anniversary" /> Anniversary
          </button>
          <button
            className={`option-button ${
              selectedSpecialDay === 'Birthday' ? 'selected' : ''
            }`}
            onClick={() => handleSpecialDaySelection('Birthday')}
          >
            <img src={BirthdayIcon} alt="Birthday" /> Birthdays
          </button>
          <button
            className={`option-button ${
              selectedSpecialDay === 'Others' ? 'selected' : ''
            }`}
            onClick={() => handleSpecialDaySelection('Others')}
          >
            <img src={OthersIcon} alt="Others" /> Others
          </button>
        </div>
      </div>
      <button
        className="continue-button"
        onClick={handleContinue}
        style={{
          backgroundColor: isContinueEnabled ? '#ff4c4c' : 'grey',
          cursor: isContinueEnabled ? 'pointer' : 'default',
        }}
        disabled={!isContinueEnabled}
      >
        Continue
      </button>
    </div>
  );
}

export default ProfileSetupButtons;
