// WelcomePage.js
import React from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import PrimaryButton from '../buttons/PrimaryButton';

const WelcomePage = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem('userInformation');
    sessionStorage.removeItem('userOtpDetails');
    sessionStorage.removeItem('userOtpPayload');
    // Perform any logout logic here (e.g., clearing session storage)
    navigate('/'); // Redirect to login or onboarding page
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      minHeight="100vh"
      padding={2}
    >
      <Typography variant="h4" gutterBottom>
        Welcome to Amealio!
      </Typography>
      <PrimaryButton variant="contained" onClick={handleLogout}>
        Logout
      </PrimaryButton>
    </Box>
  );
};

export default WelcomePage;
