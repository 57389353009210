import React, { useState } from 'react';
import { Box, Typography, MobileStepper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import splash1 from '../../assets/images/IconsandImages/splash/splash1.png';
import splash2 from '../../assets/images/IconsandImages/splash/splash2.png';
import splash3 from '../../assets/images/IconsandImages/splash/splash3.png';
import splash4 from '../../assets/images/IconsandImages/splash/splash4.png';
import splash5 from '../../assets/images/IconsandImages/splash/splash5.png';
import { assets } from '../../assets/assets';
import { Image } from 'react-bootstrap';
import PrimaryButton from '../../components/buttons/PrimaryButton';

const slides = [
  {
    title: 'Super App, Creating Superior',
    description:
      'Food is more than a means to stave off \n hunger; food is an experience.',
    image: splash1,
  },
  {
    title: 'Everyday And Everywhere',
    description: 'Spice up your daily routine.',
    image: splash2,
  },
  {
    title: 'Curated And Personalized',
    description: 'Organize and plan your meals as\n per your mood.',
    image: splash3,
  },
  {
    title: 'Social Connect',
    description: 'An appetite to socialize.',
    image: splash4,
  },
  {
    title: 'Welcome \nTo Amealio  To Meet, Eat And Connect. ',
    description: 'Refer a friend through Amealio.',
    image: splash5,
  },
];

const OnboardingSlider = () => {
  const [activeStep, setActiveStep] = useState(0);
  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep === slides.length - 1) {
      navigate('/userlocation'); // Replace with the route you want to navigate to
    } else {
      setActiveStep((prev) => prev + 1);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      textAlign="center"
      padding={2}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', my: '3' }}>
        <Image
          src={require('../../assets/images/IconsandImages/amealiologo.png')}
          width={80}
        />
      </Box>
      <Typography className="f28-800" mt={3}>
        {slides[activeStep].title}
      </Typography>
      <Typography
        className="f14-500"
        color={assets.colors.greySubText2}
        gutterBottom
        sx={{ whiteSpace: 'pre-line' }} // This enables line breaks in the description
      >
        {slides[activeStep].description}
      </Typography>
      <img
        src={slides[activeStep].image}
        alt={slides[activeStep].title}
        style={{ maxHeight: 300, marginBottom: 10 }}
      />

      <PrimaryButton onClick={handleNext} style={{ marginTop: 20 }}>
        {activeStep === slides.length - 1 ? 'Get Started' : 'Next'}
      </PrimaryButton>

      <MobileStepper
        variant="dots"
        steps={slides.length}
        position="static"
        activeStep={activeStep}
        sx={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          mt: 2,
          '& .MuiMobileStepper-dot': {
            backgroundColor: 'lightgrey', // default color for inactive dots
          },
          '& .MuiMobileStepper-dotActive': {
            backgroundColor: 'red', // color for active dot
          },
        }}
      />
    </Box>
  );
};

export default OnboardingSlider;
