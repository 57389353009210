import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from './setup/routes-manager';
import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import socketio from '@feathersjs/socketio-client';
import SocketContext from './setup/socket/SocketContext';
import { NotificationProvider } from './components/alerts/NotificationContext';
import { GoogleOAuthProvider } from '@react-oauth/google';

function App() {
  const host = process.env.REACT_APP_BASE_URL;

  // Initialize socket and feathers app once
  const socket = io(host, {
    jsonp: false,
    reconnection: true,
    reconnectionDelay: 100,
    reconnectionAttempts: 5000,
    forceNew: true,
    pingInterval: 10000,
    pingTimeout: 50000,
    transports: ['websocket'],
  });

  const app = feathers();
  app.configure(socketio(socket));
  app.configure(
    feathers.authentication({
      storage: localStorage, // Using localStorage for authentication tokens
      path: 'authentication',
    })
  );

  // Function to re-authenticate
  const _reAuthenticate = async () => {
    const userDetails = JSON.parse(sessionStorage.getItem('userDetails')); // Getting user details from session storage
    if (userDetails && userDetails.accessToken) {
      app.authentication.setAccessToken(userDetails.accessToken);
      try {
        await app.authentication.reAuthenticate();
        console.log('Socket Re-Authenticated');
      } catch (e) {
        console.error('Error during re-authentication:', e);
      }
    } else {
      console.log('Feather Token Not Found');
    }
  };

  // Effect to run the re-authentication logic on mount
  useEffect(() => {
    _reAuthenticate();
  }, [app]);

  // State to track screen size and define whether it's desktop or mobile
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 1024); // Check if the screen width is greater than or equal to 1024px
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia); // Clean up event listener
  }, []);

  return (
    <GoogleOAuthProvider clientId="859203423831-1iu6dbi778grg22anuf2nr68qsettms2.apps.googleusercontent.com">
      <SocketContext.Provider value={app}>
        <NotificationProvider>
          <div className="app-container">
            <Router>
              {/* Conditional rendering for desktop or mobile layout */}
              <div className="full-layout w-100 m-0">
                {' '}
                {/* This will now be a full-width layout */}
                <Routes /> {/* Main content for routing */}
              </div>
            </Router>
          </div>
        </NotificationProvider>
      </SocketContext.Provider>
    </GoogleOAuthProvider>
  );
}

export default App;
