import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import personalInfoImage from '../../assets/images/IconsandImages/personalInfoImage.png';
import healthPreferencesImage from '../../assets/images/IconsandImages/healthPreferencesImage.png';
import healthRestrictionsImage from '../../assets/images/IconsandImages/healthRestrictionsImage.png';
import socialPreferencesImage from '../../assets/images/IconsandImages/socialPreferencesImage.png';
import ExperienceNew from '../../assets/images/IconsandImages/ExperienceNew.png';
import image from "../../assets/images/IconsandImages/image.png"; 

const ProfileSetupMenu = () => {

    const navigate = useNavigate();

    return (
        <div className="profile-setup-container">
            <Row>
                <Col lg={6} md={6} sm={12} xs={12}>
                    <header className="profile-setup-header">
                        <h1>Would Love to know you</h1>
                        <p>The information you are about to fill is for us to provide a better experience.</p>
                        <button className="skip-button">Skip</button>
                    </header>

                    <div className="profile-setup-sections">
                        <Section
                            backgroundImage={personalInfoImage}
                            actionText="Add yours"
                            buttonPosition={{ top: '70px', right: '350px' }}
                            onActionClick={() => navigate('/profile')}
                        />
                        <Section
                            backgroundImage={healthPreferencesImage}
                            actionText="Add yours"
                            buttonPosition={{ bottom: '80px', right: '70px' }}
                            onActionClick={() => navigate('/dietarypreference')}
                        />
                        <Section
                            backgroundImage={healthRestrictionsImage}
                            actionText="Add yours"
                            buttonPosition={{ bottom: '70px', left: '70px' }}
                            onActionClick={() => navigate('/allergies')}
                        />
                        <Section
                            backgroundImage={socialPreferencesImage}
                            actionText="Add yours"
                            buttonPosition={{ bottom: '80px', right: '70px' }}
                            
                        />
                        <Section
                            backgroundImage={ExperienceNew}
                            actionText="Add yours"
                            buttonPosition={{ top: '70px', right: '350px' }}
                            onActionClick={() => navigate('/expPreference')}
                        />
                    </div>

                    <button className="do-it-later-button">Do it later</button>
                </Col>
                
                <Col lg={6} md={6} className="image-section">
                    <img src={image} alt="Decorative" className="profile-image" />
                </Col>
            </Row>
        </div>
    );
};

const Section = ({ backgroundImage, actionText, buttonPosition, onActionClick }) => {
    const buttonStyle = {
        position: 'absolute',
        ...buttonPosition,
    };

    return (
        <div
            className="profile-setup-section"
            style={{ backgroundImage: `url(${backgroundImage})` }}
        >
            <button
                className="section-action"
                style={buttonStyle}
                onClick={onActionClick} // Ensure this uses the passed prop
            >
                {actionText}
            </button>
        </div>
    );
};


export default ProfileSetupMenu;
