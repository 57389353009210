import React, { useState, useEffect } from 'react';
import ReferandEarn from '../../assets/images/IconsandImages/ReferandEarn.png';

const ReferEarnBanner = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024); 

  const updateMedia = () => {
    setIsDesktop(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => window.removeEventListener('resize', updateMedia);
  }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${ReferandEarn})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        borderRadius: '10px',
        padding: '30px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'relative',
        height: isDesktop ? '600px' : '250px', // Adjust height for mobile and desktop
        flexDirection: 'column',
        marginTop: '-60px',
        marginBottom: '20px',
        marginRight:'-30px'
      }}
    >
    </div>
  );
};

export default ReferEarnBanner;
